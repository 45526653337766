// /**************************************************
//     FONTS
//     **********************************************/
$font_prim--light:     normal 300 100% 'Rubik', sans-serif;
$font_prim:            normal 400 100% 'Rubik', sans-serif;
$font_prim--it:        italic 400 100% 'Rubik', sans-serif;
$font_prim--med:       normal 500 100% 'Rubik', sans-serif;
$font_prim--bold:      normal 700 100% 'Rubik', sans-serif;
$font_prim--bold_it:   italic 700 100% 'Rubik', sans-serif;

$font_sec:             normal 400 100% 'Open Sans', sans-serif;
$font_sec--italic:     italic 400 100% 'Open Sans', sans-serif;
$font_sec--light_it:   italic 300 100% 'Open Sans', sans-serif;

// /**************************************************
//     COLORS
//     **********************************************/

$irritant:          #fe0201; // za zamjenu nepotrebnih varijabli

$blue_prim:         #89C3E4;
$blue_sec:          #00457F;
$blue_widget:       #0282bb;

$yellow_prim:       #fde74c; // Accent yellow
$yellow_sec:        #f2d60e; // Hover yellow

$white:             #fff;
$red:               #c3423f; // form error
$black:             #000;

$grey_00:           #1e1e1e; // Text color
$grey_01:           #292929; // Text color
$grey_02:           #7a7a7a; // Grey (: Gray :)
$grey_03:           #a3a3a3; // Line colors
$grey_04:           #e0e0e0; // Line colors
$grey_05:           #f0f0f0; // Text color
$grey_06:           #525252; // Hover color

$gorenjska:         #3c8b47;
$kranj:             #B80202;

// Colors for the 'Žurnal priporoča' widget taken from their site
$zurnal_priporoca_red: #E24C5B;
$zurnal_priporoca_light_blue: #56B9F3;
$zurnal_priporoca_purple: #3D40BF;
$zurnal_priporoca_green: #59C174;
$zurnal_priporoca_lime: #62D5D5;
$zurnal_priporoca_yellow: #F3AC3C;
$zurnal_priporoca_grey: #A0AFB7;
$zurnal_priporoca_brown: #964B00;

// /**************************************************
//     SECTION COLORS
//     **********************************************/
$section_colors: (
                          // primar,  darker
    naslovnica:         ( #ffffff, #7a7a7a ),
    slovenija:          ( #2892d7, #1858c5 ),
    popotnik:           ( #3c8b47, #17381b),
    novice:             ( #2892d7, #1858c5 ),
    svet:               ( #8789c0, #5152a4 ),
    sport:              ( #9bc53d, #3b6721 ),
    magazin:            ( #f49f0a, #c35f06 ),
    varcen:             ( #e4572e, #89341c ),
    zdravje:            ( #00a6a6, #00647e ),
    avto:               ( #86615c, #4b3633 ),
    pod_streho:         ( #db5e5e, #9f3737 ),
    pod-streho:         ( #db5e5e, #9f3737 ),
    vizita-za-zivali:   ( #efca08, #a88905 ), // oba slucaja - i _
    vizita_za_zivali:   ( #efca08, #a88905 ), // oba slucaja - i _
    e_life:             ( #c0c53d, #7b7f1a ),
    gorenjska:          ( #3c8b47, #25572c ),
    gorenjska_homepage: ( #3c8b47, #25572c ),
    gorenjska_widget:   ( #3c8b47, #25572c ),
    kranj:              ( #B80202, #5f1111 ),
    homepage:           ( #89c3e4, #00457f ),
    trajnostno:         ( #89c3e4, #00457f ),
);

$navigation_colors: (
                        // primary,  hover,     non-active
    naslovnica:         ( #ffffff, #7a7a7a, $white ),
    slovenija:          ( #2892d7, #1858c5, $white ),
    popotnik:           ( #3c8b47, #17381b, $white ),
    novice:             ( #2892d7, #1858c5, $white ),
    svet:               ( #8789c0, #5152a4, $white ),
    sport:              ( #9bc53d, #3b6721, $white ),
    magazin:            ( #f49f0a, #c35f06, $white ),
    varcen:             ( #e4572e, #89341c, $white ),
    zdravje:            ( #00a6a6, #00647e, $white ),
    avto:               ( #86615c, #4b3633, $white ),
    pod_streho:         ( #db5e5e, #9f3737, $white ),
    pod-streho:         ( #db5e5e, #9f3737, $white ),
    vizita-za-zivali:   ( #efca08, #a88905, $white ), // oba slucaja - i _
    vizita_za_zivali:   ( #efca08, #a88905, $white ), // oba slucaja - i _
    e_life:             ( #c0c53d, #7b7f1a, $white ),
    gorenjska:          ( #3c8b47, #25572c, $white ),
    gorenjska_homepage: ( #3c8b47, #25572c, $white ),
    gorenjska_widget:   ( #3c8b47, #25572c, $white ),
    kranj:              ( #B80202, #5f1111, $white ),
    homepage:           ( #89c3e4, #00457f, $white ),
    trajnostno:         ( #89c3e4, #00457f, $white ),
);

// /**************************************************
//     SOCIAL NETWORKS
//     **********************************************/
$color_facebook:    #3b5998;    // facebook color
$color_twitter:     #55acee;    // twitter color
$color_googplus:    #dd4b39;    // googplus color
$color_pintrest:    #c92619;    // pintrest color
$color_viber:       #7b519d;    // viber color
$color_whatsapp:    #20b038;    // whatsapp color

// shorten
$color_fb:          $color_facebook;    // facebook color
$color_tw:          $color_twitter;     // twitter color
$color_gpl:         $color_googplus;    // googplus color
$color_pin:         $color_pintrest;    // pintrest color
$color_vib:         $color_viber;       // viber color
$color_wha:         $color_whatsapp;    // whatsapp color

$color_fb_01:       #355089;            // facebook hover color
$color_tw_01:       #4999d6;            // twitter hover color

// /**************************************************
//     DEFAULTS
//     **********************************************/
$color_background:          $grey_05;
$color_background_lg:       $white;
$color_background--black:   $black;

$default-user:              $yellow_prim;

// /**************************************************
//     TEXT OVERIDES
//     **********************************************/
$text01: $grey_00;  // title, subtitle, author, date, content, img author
$text02: $grey_00;  // default variable - pls overide with correct variable, red (#f00) added
