// ********************************
// includes/_mixins.scss
// ********************************

// @mixin font-size($sizeValue: 1.6)
// @mixin line-height($sizeValue: 2)
// @mixin clearfix()
// @mixin cf()
// @mixin border-box()
// @mixin border-radius($value: '5px')
// @mixin box-shadow($shadow)
// @mixin opacity($opacity)
// @mixin text-shadow($shadow)
// @mixin gradient($color1, $color2)
// @mixin transition($value)
// @mixin rotate($deg)
// @mixin screen($resMin, $resMax)
// @mixin max-screen($res)
// @mixin min-screen($res)
// @mixin screen-height($resMin, $resMax)
// @mixin max-screen-height($res)
// @mixin min-screen-height($res)
// @mixin respond-to($val, $query: min-width, $media: screen)

// @function em($target, $context: $base-fontSize)
// @function rem($size)

@mixin font-size($sizeValue: 1.6){
    font-size: ($sizeValue * 1) + px;
    font-size: ($sizeValue * 0.1) + rem;
}

@mixin line-height($sizeValue: 2){
    line-height: ($sizeValue * 1) + px;
    line-height: ($sizeValue * 0.1) + rem;
}

@mixin clearfix() {
    &:after {
        content: ".";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
        zoom: 1
    }
}

@mixin cf() {
    *zoom:1;

    &:before,
    &:after {
        content:"";
        display:table;
    }
    &:after {
        clear:both;
    }
}

@mixin border-box() {
    box-sizing: border-box;
}

@mixin border-radius($value: '5px') {
    border-radius: $value;
}

@mixin box-shadow($shadow) {
    box-shadow: $shadow;
}

@mixin opacity($opacity) {
    opacity:$opacity;
}

@mixin text-shadow($shadow) {
    text-shadow: $shadow;
}

@mixin gradient($color1, $color2){
    background: linear-gradient($color1, $color2);
}
// za više boje koristiti generator http://www.colorzilla.com/gradient-editor/

@mixin transition($value) {
    transition: $value ease-in-out;
}

@mixin rotate($deg) {
    transform: rotate($deg);
}

@mixin translateX($value) {
    transform: translateX($value);
}

@mixin translateY($value) {
    transform: translateY($value);
}

@mixin scale($value) {
    transform: scale($value);
}

// Photoshop-like letter spacing
@mixin letter-spacing($sizeValue){
  letter-spacing: ($sizeValue * 0.001) + em;
}

@mixin bg-cover($image){
  background: url($image) 0 0 no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

// Custom Mixins for Media Queries
// --------------------------------------------------------------------------------------------------------------------
//   @min-screen(width)                      // shortcut for @media screen and (min-width ...)
//   @max-screen(width)                      // shortcut for @media screen and (max-width ...)
//   @screen(min-width, max-width)           // shortcut for @media screen and (min-width ...) and (max-width ...)
//   ---
//   @min-screen-height(height)              // shortcut for @media screen and (min-height ...)
//   @max-screen-height(height)              // shortcut for @media screen and (max-height ...)
//   @screen-height(min-height, max-height)  // shortcut for @media screen and (min-height ...) and (max-height ...)
// --------------------------------------------------------------------------------------------------------------------
@mixin screen($resMin: '0px', $resMax: '9999px') {
    @media screen and (min-width: $resMin) and (max-width: $resMax) { @content; }
}

@mixin max-screen($res) {
    @media screen and (max-width: $res) { @content; }
}

@mixin min-screen($res) {
    @media screen and (min-width: $res) { @content; }
}

@mixin screen-height($resMin: '0px', $resMax: '9999px') {
    @media screen and (min-height: $resMin) and (max-height: $resMax) { @content; }
}

@mixin max-screen-height($res) {
    @media screen and (max-height: $res) { @content; }
}

@mixin min-screen-height($res) {
    @media screen and (min-height: $res) { @content; }
}

@mixin respond-to($val, $query: min-width, $media: screen) {
    @media #{$media} and ($query: $val) {
        @content;
    }
}

@mixin aspect-ratio($width, $height) {

    position: relative;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: calc(($height / $width) * 100%);
    }

    > .ar_content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

@function stroke($stroke, $color) {
    $shadow: ();
    $from: $stroke*-1;
    @for $i from $from through $stroke {
        @for $j from $from through $stroke {
            $shadow: append($shadow, $i*1px $j*1px 0 $color, comma);
        }
    }
    @return $shadow;
}

@mixin stroke($stroke, $color) {
    text-shadow: stroke($stroke, $color);
}

@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white){
    overflow: hidden;
    position: relative;
    line-height: $lineHeight;
    max-height: $lineHeight * $lineCount;
    margin-right: -1em;
    padding-right: 1em;
    &:before {
        content: '...';
        position: absolute;
        right: 0;
        bottom: 0;
    }
    &:after {
        content: '';
        position: absolute;
        right: 0;
        width: 1em;
        height: 1em;
        margin-top: 0.2em;
        background: $bgColor;
    }
}
