/** initial setup **/

.nano {
    position: relative;
    width: 100%;
    height: 354px;  // 5 x 70px + 4 bordera po 1px
    overflow: hidden;
}

.sidebar__feed--nano {
    @extend .nano;
    height: unset;

    @include max-screen($lg - 1) {
        height: unset!important;
    }
}

.nano > .nano-content {
    position: absolute;
    overflow: scroll;
    overflow-x: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    @include min-screen($lg - 1) {
        position: relative;
    }
}

.sidebar__feed--nano > .nano-content {
    @include max-screen($lg - 1) {
        display: flex;
        position: relative;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
    }
}

.nano > .nano-content:focus {
    outline: thin dotted;
}

.nano > .nano-content::-webkit-scrollbar {
    display: none;
}

.has-scrollbar > .nano-content::-webkit-scrollbar {
    display: block;
}

.nano > .nano-pane {
    background: rgba(235, 235, 235, 1.0);
    position: absolute;
    width: 2px;
    right: 2px;
    top: 0;
    bottom: 0;
    visibility: hidden\9;
    /* Target only IE7 and IE8 with this hack */
    opacity: .01;
    -webkit-transition: .2s;
    -moz-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
}

.nano > .nano-pane > .nano-slider {
    background: #9b9b9b;
    background: rgba(155, 155, 155, 1.0);
    position: relative;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}

.nano:hover > .nano-pane,
.nano-pane.active,
.nano-pane.flashed {
    visibility: visible\9;
    /* Target only IE7 and IE8 with this hack */
    opacity: 0.99;
}
