.input {
    background: none;
    border: 0;
}

::-webkit-input-placeholder {
    color:    inherit;
}
:-moz-placeholder {
   color:    inherit;
   opacity:  1;
}
::-moz-placeholder {
   color:    inherit;
   opacity:  1;
}
:-ms-input-placeholder {
   color: inherit;
}

// custom icons with transition
// hamburger to x
.icon-lines {

    position: relative;
    display: inline-block;
    width: 20px;
    height: 1px;
    background: #fff;

    &:before,
    &:after {
        position: absolute;
        display: inline-block;
        width: 20px;
        height: 1px;
        background: #fff;
        left: 0;
        content: '';
        @include transition(0.3s);
    }

    &:before {
        top: -5px;
    }

    &:after {
        bottom: -5px;
    }

    &.click {
        background: none;

        &:after {
            @include rotate(45deg);
            bottom: 0;
        }

        &:before {
            @include rotate(-45deg);
            top: 0;
        }
    }
}

// down to up
.icon-down {
    position: relative;
    display: inline-block;
    width: 33%; // povečavamo dodirnu zonu
    height: 50px;
    @include transition(0.3s);

    .table__header & {
        width: 100%;

        @include min-screen($md) {
            width: 33%;
        }
    }

    &:before,
    &:after {
        position: absolute;
        display: inline-block;
        width: 12px;
        height: 1px;
        background: $grey_00;
        top: 25px;
        left: 0;
        content: '';
        @include transition(0.3s);

        .language__flag & {
            width: 8px;
        }
    }

    $rightOffset: 18px;

    &:before {
        left: auto;
        right: $rightOffset + 8; // left pretvaramo u right jer je width u postocima
        @include rotate(45deg);
    }

    &:after {
        left: auto;
        right: $rightOffset; // left pretvaramo u right jer je width u postocima
        @include rotate(-45deg);
    }

    &.click {
        &:before {
            @include rotate(-45deg);
            background: #9b9b9b
        }

        &:after {
            @include rotate(45deg);
            background: #9b9b9b
        }
    }
}

.js_sticky,
.js_sticky_offset {

    @include min-screen($lg) {
        position: sticky;
        top: 56px;

        .has_subnav & {
            top: 90px;
        }
    }

    @include min-screen($xlg) {
        top: 70px;

        .has_subnav & {
            top: 100px;
        }
    }
}

.js_stickyDidomi  {
    position: sticky;
    top: 56px;

    .has_subnav & {
        top: 90px;
    }

    @include min-screen($xlg) {
        top: 70px;

        .has_subnav & {
            top: 100px;
        }
    }
}

.relative {
    position: relative;
}

@-webkit-keyframes spin {
    0%   { -webkit-transform: rotate(0deg); -ms-transform: rotate(0deg); transform: rotate(0deg);}
    100% { -webkit-transform: rotate(360deg); -ms-transform: rotate(360deg); transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    0%   { -webkit-transform: rotate(0deg); -ms-transform: rotate(0deg); transform: rotate(0deg);}
    100% { -webkit-transform: rotate(360deg); -ms-transform: rotate(360deg); transform: rotate(360deg);
    }
}
@-moz-keyframes    spin {
    0%   { -webkit-transform: rotate(0deg); -ms-transform: rotate(0deg); transform: rotate(0deg);}
    100% { -webkit-transform: rotate(360deg); -ms-transform: rotate(360deg); transform: rotate(360deg);
    }
}
@-o-keyframes      spin {
    0%   { -webkit-transform: rotate(0deg); -ms-transform: rotate(0deg); transform: rotate(0deg);}
    100% { -webkit-transform: rotate(360deg); -ms-transform: rotate(360deg); transform: rotate(360deg);
    }
}
@keyframes         spin {
    0%   { -webkit-transform: rotate(0deg); -ms-transform: rotate(0deg); transform: rotate(0deg);}
    100% { -webkit-transform: rotate(360deg); -ms-transform: rotate(360deg); transform: rotate(360deg);
    }
}

@-webkit-keyframes blob {
  0% { -webkit-transform: perspective(120px) }
  50% { -webkit-transform: perspective(120px) rotateY(180deg) }
  100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
}

@keyframes blob {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
  } 50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
  } 100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
