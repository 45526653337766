a {
    color: #1e1e1e;
}
a:link, a:active, a:visited, a:hover{
    text-decoration: none;
}
a:active, a:visited {
    outline: 0;
}

h1 {
    padding: 20px 0 0;

}

h2 {
    padding: 15px 0 0;
}

h3 {
    padding: 10px 0 0;
}

h1,
h2,
h3 {
    margin: 0;
}

/* rubik-300 - latin_latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  src: url('#{$fontsPath}/Rubik-Light.eot'); /* IE9 Compat Modes */
  src: local('Rubik Light'), local('Rubik-Light'),
       url('#{$fontsPath}/Rubik-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$fontsPath}/Rubik-Light.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$fontsPath}/Rubik-Light.woff') format('woff'); /* Modern Browsers */
}

/* rubik-regular - latin_latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: url('#{$fontsPath}/Rubik-Regular.eot'); /* IE9 Compat Modes */
  src: local('Rubik Regular'), local('Rubik-Regular'),
       url('#{$fontsPath}/Rubik-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$fontsPath}/Rubik-Regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$fontsPath}/Rubik-Regular.woff') format('woff'); /* Modern Browsers */
}

/* rubik-italic - latin_latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  src: url('#{$fontsPath}/Rubik-Italic.eot'); /* IE9 Compat Modes */
  src: local('Rubik Italic'), local('Rubik-Italic'),
       url('#{$fontsPath}/Rubik-Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$fontsPath}/Rubik-Italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$fontsPath}/Rubik-Italic.woff') format('woff'); /* Modern Browsers */
}

/* rubik-500 - latin_latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: url('#{$fontsPath}/Rubik-Medium.eot'); /* IE9 Compat Modes */
  src: local('Rubik Medium'), local('Rubik-Medium'),
       url('#{$fontsPath}/Rubik-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$fontsPath}/Rubik-Medium.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$fontsPath}/Rubik-Medium.woff') format('woff'); /* Modern Browsers */
}

/* rubik-700 - latin_latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  src: url('#{$fontsPath}/Rubik-Bold.eot'); /* IE9 Compat Modes */
  src: local('Rubik Bold'), local('Rubik-Bold'),
       url('#{$fontsPath}/Rubik-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$fontsPath}/Rubik-Bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$fontsPath}/Rubik-Bold.woff') format('woff'); /* Modern Browsers */
}

/* rubik-700italic - latin_latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  src: url('#{$fontsPath}/Rubik-BoldItalic.eot'); /* IE9 Compat Modes */
  src: local('Rubik Bold Italic'), local('Rubik-BoldItalic'),
       url('#{$fontsPath}/Rubik-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$fontsPath}/Rubik-BoldItalic.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$fontsPath}/Rubik-BoldItalic.woff') format('woff'); /* Modern Browsers */
}

// ************** Icommon ***************//
@font-face {
  font-family: 'icomoon';
  src: url('#{$fontsPath}/icomoon.eot?5esylu');
  src: url('#{$fontsPath}/icomoon.eot?5esylu#iefix') format('embedded-opentype'),
    url('#{$fontsPath}/icomoon.woff2?5esylu') format('woff2'),
    url('#{$fontsPath}/icomoon.ttf?5esylu') format('truetype'),
    url('#{$fontsPath}/icomoon.woff?5esylu') format('woff'),
    url('#{$fontsPath}/icomoon.svg?5esylu#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change ../../../fonts/ */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-comments-s:before {
  content: "\e92e";
}

.icon-comments-l:before {
  content: "\e92f";
}

.icon-arrow-down-bold-circle-outline:before {
  content: "\e92c";
}
.icon-arrow-up-bold-circle-outline:before {
  content: "\e92d";
}
.icon-camera:before {
  content: "\e92b";
}
.icon-eye-outline:before {
  content: "\e92a";
}
.icon-lead-pencil:before {
  content: "\e929";
}
.icon-arrow-left:before {
  content: "\e928";
}
.icon-google-plus:before {
  content: "\e926";
}
.icon-linkedin:before {
  content: "\e927";
}
.icon-phone-in-talk:before {
  content: "\e924";
}
.icon-whatsapp:before {
  content: "\e925";
}
.icon-account:before {
  content: "\e900";
}
.icon-account-off:before {
  content: "\e901";
}
.icon-assistant_photo:before {
  content: "\e902";
}
.icon-calendar:before {
  content: "\e903";
}
.icon-calendar-today:before {
  content: "\e904";
}
.icon-camera-party-mode:before {
  content: "\e905";
}
.icon-chat:before {
  content: "\e906";
}
.icon-check:before {
  content: "\e907";
}
.icon-checkbox-blank-outline:before {
  content: "\e908";
}
.icon-checkbox-marked:before {
  content: "\e909";
}
.icon-chevron-down:before {
  content: "\e90a";
}
.icon-chevron-left:before {
  content: "\e90b";
}
.icon-chevron-right:before {
  content: "\e90c";
}
.icon-close:before {
  content: "\e90d";
}
.icon-close-circle:before {
  content: "\e90e";
}
.icon-delete:before {
  content: "\e90f";
}
.icon-email:before {
  content: "\e910";
}
.icon-emoticon:before {
  content: "\e911";
}
.icon-emoticon-neutral:before {
  content: "\e912";
}
.icon-emoticon-sad:before {
  content: "\e913";
}
.icon-eye:before {
  content: "\e914";
}
.icon-facebook:before {
  content: "\e915";
}
.icon-home:before {
  content: "\e916";
}
.icon-instagram:before {
  content: "\e917";
}
.icon-key:before {
  content: "\e918";
}
.icon-keyboard-backspace:before {
  content: "\e919";
}
.icon-lock-outline:before {
  content: "\e91a";
}
.icon-logout:before {
  content: "\e91b";
}
.icon-magnify:before {
  content: "\e91c";
}
.icon-menu:before {
  content: "\e91d";
}
.icon-pencil:before {
  content: "\e91e";
}
.icon-plus:before {
  content: "\e91f";
}
.icon-radiobox-blank:before {
  content: "\e920";
}
.icon-radiobox-marked:before {
  content: "\e921";
}
.icon-send:before {
  content: "\e922";
}
.icon-twitter:before {
  content: "\e923";
}
