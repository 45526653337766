// /**************************************************
//     GRID & LAYOUT SETTINGS
//     **********************************************/

// GRID SPECIFIC:

$grid: 12;

// BREAKPOINTS

$xs: 539px;
$sm: 540px;
$md: 768px;
$lg: 1024px;
$xlg: 1370px;

$wxlg: 1310px;

// GUTTERS

$gutter: 28px;
$gutter_sm: 30px;
$gutter_md: 30px;
$gutter_lg: 30px;
$gutter_xlg: 30px;

// /**************************************************
//     ŠIRINA EKRANA NA KOJOJ SE PRESLAGUJU
//     COLUMN_CONTENT I COLUMN_SIDEBAR
//     **********************************************/

$column_break: $lg;
$container-max-width: 1310px;

// /**************************************************
//     FONTS
//     **********************************************/
$base-fontSize: 62.5%;
$base-lineHeight: 1.5;

// /**************************************************
//     THEME
//     **********************************************/

$fontsPath: "../../../fonts";
$imagesPath: "../../../images";
$imgPath: "../../../img";

@import 'themes/zurnal';

// Iconset (font icons)
%iconsetup {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
