/**************************************************
    CORE SETTINGS
    **********************************************/
html {
    font-size: $base-fontSize;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    @media screen and (min-width: 1024px) {
        background: #1e1e1e;
    }
}

body {
    color: #1e1e1e;
    font: $font_prim;
    font-size: 100%;
    line-height: $base-lineHeight;
    background: $color_background;
    overflow-x: hidden;

    &.nav_is_open {
        overflow: hidden;
        position: fixed;
    }

    @include min-screen($lg) {
        background: $color_background_lg;
    }

    .imagelightbox-wrapper {
        height: 100vh;
        width: 100vw;
        background-color: rgba(1,1,1,0.7);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99999999999;

        .imagelightbox-close {
            width: 5.5em;
            height: 5.5em;
            top: 6.5em;
            background-color: #2892d7;
            transition: all .3s ease-in-out;

            &:hover {
                background-color: #1858c5;
            }
        }
    }
}

figure {
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 0;
}

*:focus {
    outline: 0;
}

/**************************************************
    SITE WRAPPERS
    **********************************************/

.body_wrap {
    position: relative;
    padding: 0;
    z-index: 1;
    top: 56px;
    margin-bottom: -56px;

    .search & {
        position: relative !important;
    }

    @include min-screen($lg) {
        margin: 0;

        @include min-screen($lg) {
            background: $color_background_lg;
        }

        .has_subnav & {
            top: 91px;
            margin-bottom: -91px;
        }
    }

    @include min-screen($xlg) {
        top: 64px;
        margin-bottom: -64px;

        .has_subnav & {
            top: 104px;
            margin-bottom: -104px;
        }

        body.section--special & {
            top: 56px;
        }
    }
}

.row {
    @include min-screen($lg) {
        margin: 0 -15px;
    }
}

.container {
    margin: 0 auto;

    @include min-screen($sm) {
        max-width: 540px;
    }

    @include min-screen($lg) {
        max-width: 1024px;
    }

    @include min-screen($xlg) {
        max-width: 1310px;
    }
}

.content__wrap {
    padding: 0;

    @include max-screen($lg - 1) {
        padding-top: 1px;
        padding-bottom: 40px;
        background: $white;
        box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.08);
        overflow: hidden;
    }

    @include min-screen($lg) {
        body.section--special & {
            background-color: transparent;
        }
    }

    @include min-screen($lg) {
        body.section--special & {
            position: relative;

            &:before {
                content: "";
                position: absolute;
                top: -22px;
                right: -8px;
                left: -8px;
                height: 230px;
                margin: auto;
                border: 1px solid #fff;
                border-bottom: none;
            }
        }
    }
}

/**************************************************
    FIXED SIDEBAR
    **********************************************/

$side_width:    330px;
$side_width_xlg: 330px;

.column_content {
    position: relative;
    float: none;
    width: 100%;
    margin: 0;
    padding: 0;
    @include border-box();
    @media screen and (min-width: $sm) and (min-width: $column_break) { margin: 0 (-$side_width) 0 0; } //32
    @media screen and (min-width: $md) and (min-width: $column_break) { margin: 0 (-$side_width) 0 0; } //32
    @media screen and (min-width: $lg) and (min-width: $column_break) { margin: 0 (-$side_width) 0 0; } //48
    @media screen and (min-width: $xlg) and (min-width: $column_break) { margin: 0 (-$side_width_xlg) 0 0; } //48
    @media screen and (min-width: $column_break) { float: left; }

    .container--sidebarsmall & {
        @media screen and (min-width: $xlg) and (min-width: $column_break) { margin: 0 (-$side_width) 0 0; } //48
    }

    .container--sidebarleft & {
        @media screen and (min-width: $sm) and (min-width: $column_break) { margin: 0 0 0 (-$side_width); } //32
        @media screen and (min-width: $md) and (min-width: $column_break) { margin: 0 0 0 (-$side_width); } //32
        @media screen and (min-width: $lg) and (min-width: $column_break) { margin: 0 0 0 (-$side_width); } //48
        @media screen and (min-width: $xlg) and (min-width: $column_break) { margin: 0 0 0 (-$side_width_xlg); } //48
        @media screen and (min-width: $column_break) { float: right; }

        .container--sidebarsmall & {
            @media screen and (min-width: $xlg) and (min-width: $column_break) { margin: 0 0 0 (-$side_width); }
        }
    }

    &__inner {
        position: relative;
        padding: 0;
        @include cf();
        @include border-box();
        @media screen and (min-width: $sm) and (min-width: $column_break) { margin: 0 ($side_width) 0 0; }
        @media screen and (min-width: $md) and (min-width: $column_break) { margin: 0 ($side_width) 0 0; }
        @media screen and (min-width: $lg) and (min-width: $column_break) { margin: 0 ($side_width) 0 0; }
        @media screen and (min-width: $xlg) and (min-width: $column_break) { margin: 0 ($side_width_xlg) 0 0; }

        @include min-screen($xlg) {
            // extra white space on top of the footer on all pages
            padding: 0;
        }

        .container--sidebarsmall & {
            @media screen and (min-width: $xlg) and (min-width: $column_break) { margin: 0 ($side_width) 0 0; }
        }

        .container--sidebarleft & {
            @media screen and (min-width: $sm) and (min-width: $column_break) { margin: 0 0 0 ($side_width); }
            @media screen and (min-width: $md) and (min-width: $column_break) { margin: 0 0 0 ($side_width); }
            @media screen and (min-width: $lg) and (min-width: $column_break) { margin: 0 0 0 ($side_width); }
            @media screen and (min-width: $xlg) and (min-width: $column_break) { margin: 0 0 0 ($side_width_xlg); }

            .container--sidebarsmall & {
                @media screen and (min-width: $xlg) and (min-width: $column_break) { margin: 0 0 0 ($side_width); }
            }
        }
    }
}

.column_sidebar {
    position: relative;
    float: none;
    width: 100%;
    margin: 0;
    padding: 0;
    @include border-box();
    @include cf();
    @media screen and (min-width: $sm) and (min-width: $column_break) { width: $side_width; }
    @media screen and (min-width: $md) and (min-width: $column_break) { width: $side_width; }
    @media screen and (min-width: $lg) and (min-width: $column_break) { width: $side_width; }
    @media screen and (min-width: $xlg) and (min-width: $column_break) { width: $side_width_xlg; }
    @media screen and (min-width: $column_break) { float: right; }

    @include min-screen($xlg) {
        // extra white space on top of the footer on all pages
        padding: 0;
    }

    .container--sidebarleft & {
        @media screen and (min-width: $column_break) { float: left; }
    }

    .container--sidebarsmall & {
        @media screen and (min-width: $xlg) and (min-width: $column_break) { width: $side_width; }
    }

    body.tool_page & {
        @media screen and (min-width: $lg) {
            padding-top: 170px;
        }
    }

    body.all_tools & {
        @media screen and (min-width: $lg) {
            padding-top: 105px;
        }
    }
}

.main_snake {

    &_border {
        position: relative;

        @include min-screen($lg) {
            &:after {
                position: absolute;
                content: '';
                top: -30px;
                background: #efede8;
                height: 1px;
                width: 100%;
            }
        }
    }

    &_column {
        margin: 0 !important;

        @include min-screen($lg) {
            padding: 0 40px 0 0;
        }
    }
}

.noresult {
    padding: 0 0 20px;

    &__title {
        font: $font_sec;
        @include font-size(22);
        margin: 0;
        padding: 0 0 20px;

        @include min-screen($lg) {
            @include font-size(40);
        }
    }

    &__desc {
        @include font-size(15);
        padding: 0;
        margin: 0;

        @include min-screen($lg) {
            @include font-size(18);
        }

        a {
            color: $grey_00;
        }
    }
}

.center_me {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);

    @include min-screen(340px) {
        margin: 20px 0;
    }

    @include min-screen($sm) {
        margin: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.home {

    &--loadmorewrapcontainer {

        @include min-screen($xlg) {
            margin-bottom: -42px;
        }
    }

    &--loadmorewrap {

        @include min-screen($xlg) {
            margin: -78px 0 -10px;
        }
    }
}

// Banner helper
#divAIO1, #divAIO2 {
    height: 0;
    overflow: hidden;
}
